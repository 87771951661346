const CDN_URL = process.env.REACT_APP_IMG_URL

//BG
export const sectionBg = CDN_URL + "/assets/HomeV2/home-bg-img.jpg"
export const confettiBg = CDN_URL + "/assets/HomeV2/icons/confetti.png"

//Thumbnails
export const liveSportsImg =
  CDN_URL + "/assets/HomeV2/desktop/images/liveSportsThumb.png"
export const liveCasinoImg =
  CDN_URL + "/assets/HomeV2/desktop/images/liveCasinoThumb.png"
export const slotsImg = CDN_URL + "/assets/HomeV2/desktop/images/slotsThumb.png"
export const skyparkImg = CDN_URL + "/assets/HomeV2/desktop/images/skyparkThumb.png"
export const SkyparkBaccarat =
  CDN_URL + "/assets/HomeV2/Skypark/Skypark_Thumbnails_(Baccarat)_001.jpg"
export const SkyparkDice =
  CDN_URL + "/assets/HomeV2/Skypark/Skypark_Thumbnails_(DICE)_001.jpg"
export const SkyparkDragonTiger =
  CDN_URL + "/assets/HomeV2/Skypark/Skypark_Thumbnails_(Dragon-Tiger)_001.jpg"
export const SkyparkOddeven =
  CDN_URL + "/assets/HomeV2/Skypark/Skypark_Thumbnails_(Odd-Even)_001.jpg"
export const SkyparkSpeed =
  CDN_URL + "/assets/HomeV2/Skypark/Skypark_Thumbnails_(Speed-Baccarat)_001.jpg"
export const SkyparkSunMoon =
  CDN_URL + "/assets/HomeV2/Skypark/Skypark_Thumbnails_(Sun-Moon-Stars)_001.jpg"
export const SkyparkSwallowtail =
  CDN_URL + "/assets/HomeV2/Skypark/Skypark_Thumbnails_(Swallowtail)_001.jpg"

//Videos
export const liveSportsWebmVid =
  CDN_URL + "/assets/HomeV2/desktop/videos/liveSports.webm"
export const liveSportsMovVid =
  CDN_URL + "/assets/HomeV2/desktop/videos/liveSports.mov"
export const liveCasinoWebmVid =
  CDN_URL + "/assets/HomeV2/desktop/videos/liveCasino.webm"
export const liveCasinoMovVid =
  CDN_URL + "/assets/HomeV2/desktop/videos/liveCasino.mov"
export const slotsWebmVid = CDN_URL + "/assets/HomeV2/desktop/videos/slots.webm"
export const slotsMovVid = CDN_URL + "/assets/HomeV2/desktop/videos/slots.mov"
export const skyparkWebmVid = CDN_URL + "/assets/HomeV2/desktop/videos/skypark.webm"
export const skyparkMovVid = CDN_URL + "/assets/HomeV2/desktop/videos/skypark.mov"

//Icons
export const Basketball = CDN_URL + "/assets/HomeV2/icons/Basketball.png"
export const BlueDice = CDN_URL + "/assets/HomeV2/icons/Blue-Dice.png"
export const OrangeGem = CDN_URL + "/assets/HomeV2/icons/Orange-Gem.png"
export const PockerChip = CDN_URL + "/assets/HomeV2/icons/Poker-Chip.png"
export const Star = CDN_URL + "/assets/HomeV2/icons/Star.png"
export const Trophy = CDN_URL + "/assets/HomeV2/icons/Trophy.png"
export const VsIcon = CDN_URL + "/assets/HomeV2/icons/vs_icon_desktop.png"
export const userIcon = CDN_URL + "/assets/HomeV2/icons/user-icon.svg"
export const MaintenanceIcon =
  CDN_URL + "/assets/HomeV2/icons/icon-serviceunavailable.webp"
export const RestrictedIcon = CDN_URL + "/assets/HomeV2/icons/restricted-icon.webp"

export const FootballImg =
  CDN_URL + "/assets/HomeV2/Sports/Pre-Match-Tile-Background.jpg"
export const AmericanFootballImg =
  CDN_URL + "/assets/HomeV2/Sports/American-Football_Background.jpg"
export const BaseballImg = CDN_URL + "/assets/HomeV2/Sports/Baseball_Background.jpg"
export const BasketballImg =
  CDN_URL + "/assets/HomeV2/Sports/Basketball_Background.jpg"
export const CricketImg = CDN_URL + "/assets/HomeV2/Sports/Cricket_Background.jpg"
export const DartsImg = CDN_URL + "/assets/HomeV2/Sports/Darts_Background.jpg"
export const FieldHockeyImg =
  CDN_URL + "/assets/HomeV2/Sports/Field-Hockey_Background.jpg"
export const HandballImg = CDN_URL + "/assets/HomeV2/Sports/Handball_Background.jpg"
export const IceHockeyImg = CDN_URL + "/assets/HomeV2/Sports/Hockey_Background.jpg"
export const TableTennisImg =
  CDN_URL + "/assets/HomeV2/Sports/Table_Tennis_Background.jpg"
export const TennisImg = CDN_URL + "/assets/HomeV2/Sports/Tennis_Background.jpg"
export const VolleyballImg =
  CDN_URL + "/assets/HomeV2/Sports/Volleyball_Background.jpg"

export const AmericanFootballicon =
  CDN_URL + "/assets/HomeV2/Sports/icons/American_Football_Icon.png"
export const SoccerIcon = CDN_URL + "/assets/HomeV2/Sports/icons/Soccer_Icon.png"
export const BaseballIcon = CDN_URL + "/assets/HomeV2/Sports/icons/Baseball_Icon.png"
export const BasketballIcon =
  CDN_URL + "/assets/HomeV2/Sports/icons/Basketball_Icon.png"
export const CricketIcon = CDN_URL + "/assets/HomeV2/Sports/icons/Cricket_Icon.png"
export const DartsIcon = CDN_URL + "/assets/HomeV2/Sports/icons/Darts_Icon.png"
export const FieldHockeyIcon =
  CDN_URL + "/assets/HomeV2/Sports/icons/Field_Hockey_Icon.png" // check this
export const IceHockeyIcon = CDN_URL + "/assets/HomeV2/Sports/icons/Hockey_Icon.png"
export const HandballIcon = CDN_URL + "/assets/HomeV2/Sports/icons/Handball_Icon.png"
export const TableTennisIcon =
  CDN_URL + "/assets/HomeV2/Sports/icons/Table_Tennis_Icon.png"
export const TennisIcon = CDN_URL + "/assets/HomeV2/Sports/icons/Tennis_Icon.png"
export const VolleyballIcon =
  CDN_URL + "/assets/HomeV2/Sports/icons/Volleyball_Icon.png"
export const LiveChatIcon = CDN_URL + "/assets/HomeV2/liveChat.png"
export const HomeButtonIcon = CDN_URL + "/assets/HomeV2/homeButton.png"

export const SoccerFlag = CDN_URL + "/assets/icons/soccer.webp"
export const BaseballFlag = CDN_URL + "/assets/icons/base.webp"
export const BasketballFlag = CDN_URL + "/assets/icons/basket.webp"
export const TennisFlag = CDN_URL + "/assets/icons/tennis.webp"
export const VolleyballFlag = CDN_URL + "/assets/icons/volley.webp"
export const DefaultFlag = CDN_URL + "/assets/icons/default.webp"

// Banner
export const EventImgDesktop8 =
  CDN_URL + "/assets/HomeV2/desktop/banners/event_banner_08.png"
export const EventImgMobile8 =
  CDN_URL + "/assets/HomeV2/mobile/events/Event-Banner_08.png"
export const Suspicious = CDN_URL + "/assets/banner/home/Suspicious02.webp"
export const SmallSuspicious =
  CDN_URL + "/assets/banner/home/small/Suspicious02.webp"
export const NewSports = CDN_URL + "/assets/banner/home/NewSports.webp"
export const SmallNewSports = CDN_URL + "/assets/banner/home/small/NewSports.webp"
export const Evolution = CDN_URL + "/assets/banner/home/EvolutionCashback.webp"
export const SmallEvolution =
  CDN_URL + "/assets/banner/home/small/EvolutionCashback.webp"
export const SlotsBang = CDN_URL + "/assets/banner/home/SlotsBang.webp"
export const SmallSlotsBang = CDN_URL + "/assets/banner/home/small/SlotsBang.webp"
export const InfiniteDeposit = CDN_URL + "/assets/banner/home/infinite-deposit.webp"
export const SmallInfiniteDeposit =
  CDN_URL + "/assets/banner/home/small/infinite-deposit.webp"
export const EvoBetWin = CDN_URL + "/assets/banner/home/pc-evo-bet-win.webp"
export const SmallEvoBetWin =
  CDN_URL + "/assets/banner/home/small/mobile-evo-bet-win.webp"
export const ChickenCoke = CDN_URL + "/assets/banner/home/pc-newsignup.webp"
export const SmallChickenCoke =
  CDN_URL + "/assets/banner/home/small/mobile-newsignup.webp"
export const Uefa = CDN_URL + "/assets/banner/home/pc-uefa.webp"
export const SmallUefa = CDN_URL + "/assets/banner/home/small/mobile-uefa.webp"

// Popup banner
export const NewSportsBanner = CDN_URL + "/assets/Promotions/Popup/event1.webp"
export const ScammerBanner = CDN_URL + "/assets/Promotions/Popup/event2.webp"
export const UefaBanner = CDN_URL + "/assets/Promotions/Popup/event3.webp"
export const ChickenCokeBanner = CDN_URL + "/assets/Promotions/Popup/event4.webp"
export const DepositBanner = CDN_URL + "/assets/Promotions/Popup/event5.webp"
export const EvoBetWinBanner = CDN_URL + "/assets/Promotions/Popup/event6.webp"
export const SlotsBanner = CDN_URL + "/assets/Promotions/Popup/event7.webp"
export const MilageBanner = CDN_URL + "/assets/Promotions/Popup/event8.webp"
export const ComboBonusBanner = CDN_URL + "/assets/Promotions/Popup/event9.webp"
export const FreeGameBanner = CDN_URL + "/assets/Promotions/Popup/event10.webp"
export const NewMemberBanner = CDN_URL + "/assets/Promotions/Popup/event11.webp"
export const SportsExistingMemberBanner =
  CDN_URL + "/assets/Promotions/Popup/event12.webp"
export const CasinoFirstDepoBanner =
  CDN_URL + "/assets/Promotions/Popup/event13.webp"
export const MaintenanceBanner =
  CDN_URL + "/assets/Promotions/Popup/maintenance.webp"
export const SmallMaintenanceBanner =
  CDN_URL + "/assets/Promotions/Popup/maintenance-mobile.webp"

// Live - Prematch SWiper
export const GlassSeperator = CDN_URL + "/assets/HomeV2/Glass_separator.png"
export const PlaceholderFlag = CDN_URL + "/assets/HomeV2/Place-Holder-flag.jpg"
export const FlagOverlayV1 = CDN_URL + "/assets/HomeV2/Flag-Folds-Overlay_v1.png"
export const FlagOverlayV2 = CDN_URL + "/assets/HomeV2/Flag-Folds-Overlay_v2.png"
export const sportsIcon = CDN_URL + "/assets/HomeV2/icons/sports-icon.png"
export const LiveIcon = CDN_URL + "/assets/HomeV2/icons/live_icon.png"
export const LiveIconDesktop = CDN_URL + "/assets/HomeV2/icons/live_icon_desktop.png"
export const EmptyPrematch =
  CDN_URL + "/assets/HomeV2/desktop/images/emptyPrematch.png"
export const EmptyPrematchMov =
  CDN_URL + "/assets/HomeV2/desktop/videos/emptyPrematch.mov"
export const EmptyPrematchWebm =
  CDN_URL + "/assets/HomeV2/desktop/videos/emptyPrematch.webm"
export const EmptyPrematchMobile =
  CDN_URL + "/assets/HomeV2/mobile/cardPng/emptyPrematch.png"
export const EmptyPrematchMobileVideo =
  CDN_URL + "/assets/HomeV2/mobile/cardsMp4/emptyPrematch.mp4"
export const LiveSportsImg =
  CDN_URL + "/assets/HomeV2/mobile/cardPng/liveSportsTile.png"
export const LiveSportsMp4 =
  CDN_URL + "/assets/HomeV2/mobile/cardsMp4/liveSportsTile.mp4"

// Mobile Cards
export const mobileVsIcon = CDN_URL + "/assets/HomeV2/icons/vs_Icon_mobile.png"
export const SkyparkImg = CDN_URL + "/assets/HomeV2/mobile/cardPng/skypark.png"
export const CasinoImg = CDN_URL + "/assets/HomeV2/mobile/cardPng/liveCasino.png"
export const SlotsImg = CDN_URL + "/assets/HomeV2/mobile/cardPng/slots.png"
export const FreeGameImg = CDN_URL + "/assets/HomeV2/mobile/cardPng/freeGames.jpg"

export const SkyparkMp4 = CDN_URL + "/assets/HomeV2/mobile/cardsMp4/skypark.mp4"
export const CasinoMp4 = CDN_URL + "/assets/HomeV2/mobile/cardsMp4/liveCasino.mp4"
export const SlotsMp4 = CDN_URL + "/assets/HomeV2/mobile/cardsMp4/slots.mp4"
