import clone from "lodash/clone"
import {
  Bet365Game,
  BetSlip,
  BetSlipDetail,
  BetSlipMeta,
  BetSlipType,
  CustomOdds,
  LeagueDetail,
  M3U8,
  Round,
  Charge,
  Result,
} from "types/Bet365"
import create from "zustand"

import {v4 as uuidv4} from "uuid"
import {persist} from "zustand/middleware"

export const recordKey = ({
  game_id,
  league_id,
  round,
  marketId,
  oddId,
}: {
  game_id: number
  league_id: number
  round: number
  marketId: number
  oddId: number
}): string => {
  return `${game_id}${league_id}${round}${marketId}${oddId}`
}

type GameSettings = {
  max_bet: number
  min_bet: number
  max_win: number
  max_odds: number
}

// BET 365 STORE

type Bet365Store = {
  isActive: boolean
  setIsActive: (value: boolean) => void
  games: Bet365Game[]
  showLoginModal: boolean

  selectedGame?: Bet365Game
  selectedRound?: Round | null
  selectedleague?: string
  selectedLeagueDetail?: LeagueDetail
  isLeagueLoading: boolean
  isGamesLoading: boolean
  isVideoLoading: boolean
  showResults?: boolean
  isRefresh: boolean
  setIsRefresh: (value: boolean) => void
  setGames: (games: Bet365Game[]) => void
  setSelectedGame: (game: Bet365Game) => void
  setSelectedleague: (league_code: string) => void
  setSelectedleagueDetail: (leagueDetail: LeagueDetail) => void
  setSelectedRound: (round: Round | null) => void
  removeExpiredLeagueRound: () => void
  removeExpireRounds: (expiredRounds: number[]) => void

  setShowResults: (state: boolean) => void

  addLeagueRounds: (rounds: Round[], league_detail: LeagueDetail) => void
  openLoginModal: () => void
  closeLoginModal: () => void
  setIsLeagueLoading: (isLeagueLoading: boolean) => void
  setIsGamesLoading: (isGamesLoading: boolean) => void
  setIsVideoLoading: (isVideoLoading: boolean) => void

  settings: GameSettings
  setSettings: (settings: GameSettings) => void

  activeStep: number
  setActiveStep: (value: number) => void
  nextActiveStep: () => void
  prevActiveStep: () => void
  betSlipButtonClick: () => void
  setBetSlipButtonClick: (value: () => void) => void
  requestLogin: () => void
  setRequestLogin: (value: () => void) => void
}

export const useBet365Store = create<Bet365Store>((set, get) => ({
  games: [],
  isActive: false,
  showLoginModal: false,
  isLeagueLoading: true,
  isGamesLoading: true,
  isVideoLoading: true,
  isRefresh: false,
  activeStep: 0,
  requestLogin: null,
  betSlipButtonClick: null,
  setBetSlipButtonClick: betSlipButtonClick => set({betSlipButtonClick}),
  setRequestLogin: requestLogin => set({requestLogin}),
  setActiveStep: activeStep => set({activeStep}),
  setIsRefresh: isRefresh => set({isRefresh}),
  setIsActive: isActive => set({isActive}),
  setShowResults: (state: boolean) => {
    set({showResults: state})
  },
  setSelectedleague: (league_code: string) => set({selectedleague: league_code}),
  setSelectedRound: (round: Round | null) => set({selectedRound: round}),
  setSelectedleagueDetail: (leagueDetail: LeagueDetail) =>
    set({selectedLeagueDetail: leagueDetail}),
  setGames: (games: Bet365Game[]) => set({games}),
  setSelectedGame: (selectedGame: Bet365Game) => set({selectedGame}),
  nextActiveStep: () => {
    const activeStep = get().activeStep
    const isLast = activeStep === 6
    const nextStep = isLast ? 0 : activeStep + 1
    set({activeStep: nextStep})
  },
  prevActiveStep: () => {
    const activeStep = get().activeStep
    const isFirst = activeStep === 0
    const nextStep = isFirst ? 0 : activeStep - 1
    set({activeStep: nextStep})
  },
  removeExpiredLeagueRound: async () => {
    const {selectedLeagueDetail, selectedRound} = get()
    if (!selectedLeagueDetail || selectedLeagueDetail.rounds.length === 0) {
      return
    }

    // if there is only one round for that legaue
    if (selectedLeagueDetail.rounds.length === 1) {
      set({
        selectedLeagueDetail: {
          ...selectedLeagueDetail,
          rounds: [],
        },
        selectedRound: null,
      })

      return
    }

    const [firstRound, secondRound, ...remaining] = selectedLeagueDetail.rounds

    let currentSelectedRound = selectedRound

    // if current round is first round, set the second round as selected round
    if (currentSelectedRound?.round === firstRound.round) {
      currentSelectedRound = secondRound
    }

    set({
      selectedLeagueDetail: {
        ...selectedLeagueDetail,
        rounds: [secondRound, ...remaining],
      },
      selectedRound: currentSelectedRound,
    })
  },
  removeExpireRounds: (expiredRounds: number[]) => {
    const {selectedLeagueDetail} = get()
    if (!selectedLeagueDetail || selectedLeagueDetail.rounds.length === 0) {
      return
    }

    const newRounds = selectedLeagueDetail.rounds.filter(
      r => !expiredRounds.includes(r.round),
    )

    set({
      selectedLeagueDetail: {
        ...selectedLeagueDetail,
        rounds: newRounds,
      },
      selectedRound: newRounds[0],
    })
  },
  addLeagueRounds: (rounds: Round[], league_detail: LeagueDetail) => {
    const {selectedLeagueDetail} = get()
    const leagueDetailClone = clone(selectedLeagueDetail)
    leagueDetailClone.rounds = [...leagueDetailClone.rounds, ...rounds]
    set({
      selectedLeagueDetail: leagueDetailClone,
    })
  },
  openLoginModal: () => set({showLoginModal: true}),
  closeLoginModal: () => set({showLoginModal: false}),
  setIsLeagueLoading: (isLeagueLoading: boolean) => set({isLeagueLoading}),
  setIsGamesLoading: (isGamesLoading: boolean) => set({isGamesLoading}),
  setIsVideoLoading: (isVideoLoading: boolean) => set({isVideoLoading}),
  settings: {
    min_bet: 10000,
    max_bet: 10000000,
    max_odds: 5000,
    max_win: 20000000,
  },
  setSettings: (settings: GameSettings) => {
    set({settings})
  },
}))

// BET SLIP STORE

type BetSlipStore = {
  betSlips: BetSlip[]
  betSlipsRecord: Record<number, string>
  betSlipsOpen: boolean
  setBetSlipsOpen: (state: boolean) => void
  setBetSlips: (betSlips: BetSlip[]) => void
  addBetSlip: (
    betSlip: BetSlipMeta[],
    detail: BetSlipDetail,
    type?: BetSlipType,
  ) => void
  toggleBetSlip: (id: string) => void
  removeBetSlip: (id: string) => void
  removeAllBetSlips: () => void
  removeActiveBetSlips: () => void
  setBetslipAmount: (id: string, amount: number) => void
  getBetSlipId: (code: string, timer: string) => string
}

export const useBetSlipStore = create<BetSlipStore>(
  persist(
    (set, get) => ({
      betSlipsOpen: false,
      betSlipsRecord: {},
      betSlips: [],
      setBetSlipsOpen: (state: boolean) => set({betSlipsOpen: state}),
      setBetSlips: (betSlips: BetSlip[]) => {
        set({betSlips: betSlips})
      },
      // ADDING OF BET SLIP
      addBetSlip: (
        betSlip: BetSlipMeta[],
        detail: BetSlipDetail,
        type = "default",
      ) => {
        const slip: BetSlip = {
          isActive: true,
          meta: betSlip.map(item => {
            return {...item, transaction_id: uuidv4()}
          }),
          id: uuidv4(),
          detail: detail,
          type,
        }

        const {id, meta} = slip

        const {
          game_id,
          round,
          league_id,
          charge_details: [chrg],
        } = meta[0]

        if (type !== "default") {
          set(state => ({
            betSlips: [...state.betSlips, slip],
          }))
        } else {
          const key = recordKey({
            game_id,
            league_id,
            marketId: chrg.market_id,
            round,
            oddId: chrg.odds_id,
          })
          set(state => ({
            betSlips: [slip, ...state.betSlips],
            betSlipsRecord: {
              ...state.betSlipsRecord,
              [key]: id,
            },
          }))
        }
      },
      // REMOVE ALL BETSLIPS
      removeAllBetSlips: () => {
        set({
          betSlips: [],
          betSlipsRecord: {},
        })
      },

      // REMOVE ACTIVE BETSLIP

      removeActiveBetSlips: () => {
        set(({betSlips, betSlipsRecord}) => {
          const newBetSlipsRecord = {...betSlipsRecord}

          const newBetSlips = betSlips.filter(e => !e.isActive)
          const newBetSlipsKeys = newBetSlips.map(e => e.id)

          for (const key in newBetSlipsRecord) {
            if (!newBetSlipsKeys.includes(newBetSlipsRecord[key]))
              delete newBetSlipsRecord[key]
          }

          return {
            betSlips: newBetSlips,
            betSlipsRecord: newBetSlipsRecord,
          }
        })
      },
      // TOGGLING OF BET SLIP
      toggleBetSlip: (id: string) => {
        const {betSlips} = get()

        const betSlipsClone = clone(betSlips)
        const betIndex = betSlipsClone.findIndex(x => x.id === id)
        betSlips[betIndex].isActive = !betSlipsClone[betIndex].isActive

        set({
          betSlips: [...betSlipsClone],
        })
      },

      // REMOVING OF BETSLIP
      removeBetSlip: (id: string) => {
        set(state => {
          // REMOVE BET SLIP FROM THE BET SLIP RECORD
          const newBetSlipRecord = {...state.betSlipsRecord}
          for (const key in newBetSlipRecord) {
            if (newBetSlipRecord[key] === id) delete newBetSlipRecord[key]
          }

          // UPDATE STATE
          return {
            betSlips: state.betSlips.filter(x => x.id !== id),
            betSlipsRecord: newBetSlipRecord,
          }
        })
      },

      // SETTING BET SLIP AMOUNT
      setBetslipAmount: (id: string, amount: number) => {
        const {betSlips} = get()

        const betSlipsClone = clone(betSlips)
        const betIndex = betSlipsClone.findIndex(x => x.id === id)
        for (const metaItem of betSlipsClone[betIndex].meta) {
          metaItem.amount = amount
        }

        set({
          betSlips: [...betSlipsClone],
        })
      },
      getBetSlipId: (code: string, timer: string) => {
        const betSlips = get().betSlips
        if (betSlips) {
          const betslip = betSlips.filter(item => {
            const detail = item?.detail
            const isCode = detail?.game_code === code
            const isRound = detail?.timer === timer

            return isCode && isRound
          })

          return betslip && betslip[0]?.id
        }
      },
    }),
    {
      name: "bet365-betslip", // unique name
    },
  ),
)

// TIMER STORE

type TimerStore = {
  serverTimestamp: number

  tick: () => void
  setServerDAte: (serverTimestamp: number) => void
  getServerDAte: () => number
  getRemainingSeconds: (roundTimestamp: number) => number
}

export const useTimerStore = create<TimerStore>((set, get) => ({
  serverTimestamp: +new Date(),
  tick: () => {
    const {serverTimestamp} = get()

    set({serverTimestamp: serverTimestamp + 1000})
  },
  setServerDAte: serverTimestamp => {
    set({serverTimestamp})
  },
  getServerDAte: () => {
    const {serverTimestamp} = get()
    return serverTimestamp
  },
  getRemainingSeconds: roundTimestamp => {
    const serverTimestamp = get().serverTimestamp
    const roundString = String(roundTimestamp)
    const currentDateSeconds = Math.floor(new Date(serverTimestamp).getTime() / 1000)
    const roundDateSeconds = Math.floor(
      new Date(
        Date.UTC(
          parseInt(roundString.substring(0, 4)),
          parseInt(roundString.substring(4, 6)) - 1,
          parseInt(roundString.substring(6, 8)),
          parseInt(roundString.substring(8, 10)),
          parseInt(roundString.substring(10, 12)),
        ),
      ).getTime() / 1000,
    )

    return roundDateSeconds - currentDateSeconds
  },
}))

// FORECAST BEST ENTRY STORE

export type MultiBetColumn = "first" | "second" | "third" | "any"
type SelectType = "default" | "any" | "unset"

type MultiBetEntry = {
  league_id: number
  leagueName: string
  game_id: number
  game_code: string
  round: number
  timer: string

  forecast?: ForecastEntry
  tricast?: TricastEntry
}

export type ForecastEntry = {
  market_id: number
  marketName: string
  marketValue: number

  first: Record<number, CustomOdds>
  second: Record<number, CustomOdds>

  type?: SelectType
}

export type TricastEntry = {
  third?: Record<number, CustomOdds>
} & ForecastEntry

export type EntryType = "forecast" | "tricast"

type AddMultiBetEntryParam = {
  league_id: number
  game_id: number
  game_code: string

  leagueName: string

  timer: string
  round: number

  data: {
    market_id: number
    marketValue: number
    marketName: string
    odd: CustomOdds
    column: MultiBetColumn
    type: EntryType
  }
}

type MultiBetEntryStore = {
  entries: MultiBetEntry[]
  currentEntry?: MultiBetEntry

  addEntry: (params: AddMultiBetEntryParam) => void
  removeEntry: (code: string, timer: string) => void
  setCurrentEntry: (params?: Partial<AddMultiBetEntryParam>) => void
  removeCurrentEntry: () => void
  clearExpiredEntries: (getSeconds: (roundTimestamp: number) => number) => void
  clearAll: () => void
}

const selectUnselectOdd = (
  entry: MultiBetEntry,
  type: EntryType,
  column: MultiBetColumn,
  odd: CustomOdds,
) => {
  const existingOdd = entry[type][column][odd.id]
  if (!existingOdd) {
    entry[type][column] = {}
    entry[type][column][odd.id] = {...odd}
  } else delete entry[type][column][odd.id]
}

const updateSelectedMarketType = (
  entry: MultiBetEntry,
  type: EntryType,
  column: MultiBetColumn,
) => {
  const isThirdColumnEmpty =
    type === "forecast" || Object.keys(entry.tricast.third).length === 0

  if (
    Object.keys(entry[type].first).length === 0 &&
    Object.keys(entry[type].second).length === 0 &&
    isThirdColumnEmpty
  ) {
    // IF FIRST SECOND AND THIRD ENTRY IS EMPTY THEN SET TYPE TO unset
    entry[type].type = "unset"
  } else if (column === "any") {
    // if clicked column is any then set type to any
    entry[type].type = "any"
  } else {
    entry[type].type = "default"
  }
}

export const useMultiBetslipStore = create<MultiBetEntryStore>(
  persist(
    (set, get) => ({
      entries: [],
      currentEntry: undefined,
      addEntry: ({
        league_id,
        game_id,
        game_code,
        leagueName,
        timer,
        round,
        data: {column, odd, type, marketName, marketValue, market_id},
      }: AddMultiBetEntryParam) => {
        const {currentEntry} = get()
        let entry = currentEntry ? {...currentEntry} : undefined

        // ADD NEW ENTRY
        if (!entry) {
          // SET ENTRY DETAILS
          entry = {
            league_id,
            game_id,
            game_code,
            leagueName,
            round,
            timer,
          }
        }

        if (!entry[type]) {
          // SET MARKET TYPE DETAUKS
          entry[type] = {
            ...entry[type],
            marketName,
            marketValue,
            market_id,
            first: {},
            second: {},
          }

          // // SET THIRD COLUMN
        }
        selectUnselectOdd(entry, type, column, odd)
        updateSelectedMarketType(entry, type, column)

        set({
          currentEntry: entry,
        })
      },
      removeEntry: (code: string, timer: string) => {
        const entries = get().entries
        const currentEntry = get().currentEntry

        const newEntries = entries.filter(item => {
          const isCode = item.game_code === code
          const isRound = item.timer !== timer
          return isCode && isRound
        })

        const current = entries.filter(item => {
          const isCode = item.game_code === code
          const isRound = item.timer === timer
          return isCode && isRound
        })[0]

        let clearCurrent = false
        if (current) {
          const code = current?.game_code
          const timer = current?.timer

          const currentCode = currentEntry?.game_code
          const currentTimer = currentEntry?.timer

          const matchCode = code === currentCode
          const matchTimer = timer === currentTimer
          clearCurrent = matchCode && matchTimer
        }

        if (clearCurrent || !current)
          set({
            currentEntry: undefined,
            entries: [...newEntries],
          })
        else
          set({
            entries: [...newEntries],
          })
      },
      clearAll: () => {
        set({currentEntry: undefined, entries: []})
      },
      setCurrentEntry: (
        params: Omit<
          AddMultiBetEntryParam,
          "market_id" | "marketName" | "pos" | "odd" | "game_code" | "marketValue"
        >,
      ) => {
        const {entries, currentEntry} = get()

        // before switching try to update entries using old entry value
        if (currentEntry) {
          const newSetOfEntries = entries.filter(
            x =>
              x.league_id !== currentEntry.league_id ||
              x.game_id !== currentEntry.game_id ||
              x.round !== currentEntry.round,
          )
          set({entries: [...newSetOfEntries, currentEntry]})
        }

        if (!params) {
          set({currentEntry: undefined})
          return
        }

        const {league_id, game_id, round} = params
        const newCurrentEntry = entries.find(
          x =>
            x.league_id === league_id && x.game_id === game_id && x.round === round,
        )

        set({currentEntry: newCurrentEntry})
      },
      removeCurrentEntry: () => {
        const {entries, currentEntry} = get()
        if (currentEntry) {
          const newSetOfEntries = entries.filter(
            x =>
              x.league_id !== currentEntry.league_id ||
              x.game_id !== currentEntry.game_id ||
              x.round !== currentEntry.round,
          )
          set({entries: [...newSetOfEntries]})
        }
        set({currentEntry: undefined})
      },
      clearExpiredEntries: (getSeconds: (roundTimestamp: number) => number) => {
        const {currentEntry, entries} = get()

        if (currentEntry && getSeconds(currentEntry.round) < 0) {
          set({currentEntry: undefined})
        }

        set({
          entries: entries.filter(entry => getSeconds(entry.round) > -1),
        })
      },
    }),
    {
      name: "bet365-forecast-betslip", // unique name
    },
  ),
)

// MRU8 STORE
type M3u8Store = {
  m3u8?: M3U8

  setM3u8: (m3u8?: M3U8) => void
}

export const useM3u8Store = create<M3u8Store>(
  persist(
    set => ({
      m3u8: undefined,
      setM3u8: (m3u8: M3U8) => {
        set({m3u8})
      },
    }),
    {
      name: "bet365-mru8", // unique name
    },
  ),
)

// type ResultsStore = {
//   resultsOpen: boolean

//   setResultsOpen: (open: boolean) => void
// }

// export const useResultsStore = create<ResultsStore>(
//   persist(
//     set => ({
//       resultsOpen: false,
//       setResultsOpen: (open: boolean) => {
//         set({resultsOpen: open})
//       },
//     }),
//     {name: "results-store"},
//   ),
// )

type ModalStatus = "confirm" | "error" | "hide" | "unauthorized" | "failedBets"

// FREE BET 365 STORE
type FreeBet365Store = {
  modalStatus: ModalStatus
  game: Bet365Game
  isLoading: boolean
  isEnabled: boolean
  isLaunched: boolean
  gameId: number
  gameCode: string
  selectedleague: string
  targetCredit: number
  virtualCredit: number
  rewardAmount: number
  endTime: number
  startTime: number
  minBet: number
  maxBet: number
  selectedRound: Round
  selectedLeagueDetail: LeagueDetail
  onGoingBets: Charge[]
  betHistory: Charge[]
  results: Result[]
  failedBets: any[]
  placeBet: () => void
  setFailedBets: (bets: any[]) => void
  setPlaceBet: (value: () => void) => void
  setModalStatus: (value: ModalStatus) => void
  setGame: (value: Bet365Game) => void
  setGameId: (value: number) => void
  setGameCode: (value: string) => void
  setSelectedleague: (value: string) => void
  setSelectedleagueDetail: (value: LeagueDetail) => void
  setSelectedRound: (value: Round) => void
  setIsLoading: (value: boolean) => void
  setIsEnabled: (value: boolean) => void
  setIsLaunched: (value: boolean) => void
  setTargetCredit: (value: number) => void
  setVirtualCredit: (value: number) => void
  setRewardAmount: (value: number) => void
  setEndTime: (value: number) => void
  setStartTime: (value: number) => void
  setMinBet: (value: number) => void
  setMaxBet: (value: number) => void
  setOnGoingBets: (value: Charge[]) => void
  setBetHistory: (value: Charge[]) => void
  setResults: (value: Result[]) => void
  countdownTick: () => void
  removeExpiredLeagueRound: () => void
  removeExpireRounds: (value: number[]) => void
  addLeagueRounds: (value: Round[]) => void
  resetStore: () => void
}

export const useFreeBet365Store = create<FreeBet365Store>((set, get) => ({
  modalStatus: "hide",
  game: null,
  isLoading: false,
  isEnabled: false,
  isLaunched: false,
  gameId: 0,
  gameCode: "soccer",
  selectedleague: null,
  targetCredit: 0,
  virtualCredit: 0,
  rewardAmount: 0,
  endTime: 0,
  startTime: 0,
  minBet: 0,
  maxBet: 0,
  selectedRound: null,
  selectedLeagueDetail: null,
  onGoingBets: [],
  betHistory: [],
  results: [],
  placeBet: null,
  failedBets: [],
  setGame: game => set({game}),
  setFailedBets: failedBets => set({failedBets}),
  setPlaceBet: placeBet => set({placeBet}),
  setModalStatus: modalStatus => set({modalStatus}),
  setGameId: gameId => set({gameId}),
  setGameCode: gameCode => set({gameCode}),
  setSelectedleague: selectedleague => set({selectedleague}),
  setSelectedRound: selectedRound => set({selectedRound}),
  setSelectedleagueDetail: selectedLeagueDetail => set({selectedLeagueDetail}),
  setIsLoading: isLoading => set({isLoading}),
  setIsEnabled: isEnabled => set({isEnabled}),
  setIsLaunched: isLaunched => set({isLaunched}),
  setTargetCredit: targetCredit => set({targetCredit}),
  setVirtualCredit: virtualCredit => set({virtualCredit}),
  setRewardAmount: rewardAmount => set({rewardAmount}),
  setEndTime: endTime => set({endTime}),
  setStartTime: startTime => set({startTime}),
  setMinBet: minBet => set({minBet}),
  setMaxBet: maxBet => set({maxBet}),
  setOnGoingBets: onGoingBets => set({onGoingBets}),
  setBetHistory: betHistory => set({betHistory}),
  setResults: results => set({results}),
  countdownTick: () => {
    const endTime = get().endTime
    const second = endTime - 1
    if (second >= 0) set({endTime: second})
    else set({endTime: 0, isEnabled: false, virtualCredit: 0})
  },
  removeExpiredLeagueRound: async () => {
    const {selectedLeagueDetail, selectedRound} = get()
    if (!selectedLeagueDetail || selectedLeagueDetail.rounds.length === 0) {
      return
    }

    // if there is only one round for that legaue
    if (selectedLeagueDetail.rounds.length === 1) {
      set({
        selectedLeagueDetail: {
          ...selectedLeagueDetail,
          rounds: [],
        },
        selectedRound: null,
      })

      return
    }

    const [firstRound, secondRound, ...remaining] = selectedLeagueDetail.rounds

    let currentSelectedRound = selectedRound

    // if current round is first round, set the second round as selected round
    if (currentSelectedRound?.round === firstRound.round) {
      currentSelectedRound = secondRound
    }

    set({
      selectedLeagueDetail: {
        ...selectedLeagueDetail,
        rounds: [secondRound, ...remaining],
      },
      selectedRound: currentSelectedRound,
    })
  },
  removeExpireRounds: (expiredRounds: number[]) => {
    const {selectedLeagueDetail} = get()
    if (!selectedLeagueDetail || selectedLeagueDetail.rounds.length === 0) {
      return
    }

    const newRounds = selectedLeagueDetail.rounds.filter(
      r => !expiredRounds.includes(r.round),
    )

    set({
      selectedLeagueDetail: {
        ...selectedLeagueDetail,
        rounds: newRounds,
      },
      selectedRound: newRounds[0],
    })
  },
  addLeagueRounds: (rounds: Round[]) => {
    const {selectedLeagueDetail} = get()
    const leagueDetailClone = clone(selectedLeagueDetail)
    leagueDetailClone.rounds = [...leagueDetailClone.rounds, ...rounds]
    set({
      selectedLeagueDetail: leagueDetailClone,
    })
  },
  resetStore: () => {
    set({
      modalStatus: "hide",
      game: null,
      isLoading: false,
      isEnabled: false,
      isLaunched: false,
      gameId: 0,
      gameCode: "soccer",
      selectedleague: null,
      targetCredit: 0,
      virtualCredit: 0,
      rewardAmount: 0,
      endTime: 0,
      startTime: 0,
      minBet: 0,
      maxBet: 0,
      selectedRound: null,
      selectedLeagueDetail: null,
      onGoingBets: [],
      betHistory: [],
      results: [],
      failedBets: [],
    })
  },
}))
