import {CSSProperties} from "react"
import {ThemeInterface} from "."

interface CSSCustomProperties extends CSSProperties, ThemeInterface {}

export const getCustomColors = (theme: any): CSSCustomProperties => {
  return {
    "--headerHoverColor": theme?.colors.headerHoverColor,
    "--containerTopBackground": theme?.colors.containerTopBackground,
    "--containerBottomBackground": theme?.colors.containerBottomBackground,
    "--topTitleBackground": theme?.colors.topTitleBackground,
    "--pageTitleColor": theme?.colors.pageTitleColor,
    "--pageTitleHoverColor": theme?.colors.pageTitleHoverColor,
    "--dataPickerInputBackground": theme?.colors.dataPickerInputBackground,
    "--dataPickerInputBoxShadow": theme?.colors.dataPickerInputBoxShadow,
    "--dataPickerInputBackdropFilter": theme?.colors.dataPickerInputBackdropFilter,
    "--dataPickerModalBackground": theme?.colors.dataPickerModalBackground,
    "--dataPickerModalBoxshadow": theme?.colors.dataPickerModalBoxshadow,
    "--dataPickerModalBackdropFilter": theme?.colors.dataPickerModalBackdropFilter,
    "--dataPickerModalFilterbuttonBackground":
      theme?.colors.dataPickerModalFilterbuttonBackground,
    "--dataPickerModalFilterbuttonActiveBackground":
      theme?.colors.dataPickerModalFilterbuttonActiveBackground,
    "--dataPickerModalFilterbuttonColor":
      theme?.colors.dataPickerModalFilterbuttonColor,
    "--dataPickerModalConfirmButtonBackground":
      theme?.colors.dataPickerModalConfirmButtonBackground,
    "--dataPickerModalCancelButtonBackground":
      theme?.colors.dataPickerModalCancelButtonBackground,
    "--dataPickerModalRdrStartEndEdgeBackground":
      theme?.colors.dataPickerModalRdrStartEndEdgeBackground,
    "--dataPickerModalRdrWeekDayColor": theme?.colors.dataPickerModalRdrWeekDayColor,
    "--hsitoryTabBackground": theme?.colors.hsitoryTabBackground,
    "--tabBorderBottom": theme?.colors.tabBorderBottom,
    "--hsitoryTabTotalValueColor": theme?.colors.hsitoryTabTotalValueColor,
    "--hsitoryTabTotalHeadColor": theme?.colors.hsitoryTabTotalHeadColor,
    "--userCardBackground": theme?.colors.userCardBackground,
    "--userCardTitlesColor": theme?.colors.userCardTitlesColor,
    "--userCardRibbonLevelNameColor": theme?.colors.userCardRibbonLevelNameColor,
    "--userCardRibbonLevelNameOpacity": theme?.colors.userCardRibbonLevelNameOpacity,
    "--userCardProgressBarDesktopColor":
      theme?.colors.userCardProgressBarDesktopColor,
    "--sidebarBackground": theme?.colors.sidebarBackground,
    "--sidebarItemColor": theme?.colors.sidebarItemColor,
    "--sidebarItemActiveColor": theme?.colors.sidebarItemActiveColor,
    "--sidebarItemActiveIconColor": theme?.colors.sidebarItemActiveIconColor,
    "--tabsContainerBackground": theme?.colors.tabsContainerBackground,
    "--tabsColor": theme?.colors.tabsColor,
    "--tabsActiveColor": theme?.colors.tabsActiveColor,
    "--tabsUnderlineColor": theme?.colors.tabsUnderlineColor,
    "--tabsUnderlineActiveColor": theme?.colors.tabsUnderlineActiveColor,
    "--tabsInputLabelBackground": theme?.colors.tabsInputLabelBackground,
    "--tabsInputPlaceholder": theme?.colors.tabsInputPlaceholder,
    "--preAmountButtonBackground": theme?.colors.preAmountButtonBackground,
    "--preAmountButtonColor": theme?.colors.preAmountButtonColor,
    "--preAmountButtonActiveBackground":
      theme?.colors.preAmountButtonActiveBackground,
    "--infoLabelColor": theme?.colors.infoLabelColor,
    "--readbuttonBackground": theme?.colors.readbuttonBackground,
    "--mobileInputLabelBackground": theme?.colors.mobileInputLabelBackground,
    "--tabletTopTabsContainerBackground":
      theme?.colors.tabletTopTabsContainerBackground,
    "--myInfoColor": theme?.colors.myInfoColor,
    "--inputFieldErrorColor": theme?.colors.inputFieldErrorColor,
    "--tabsInputLabelBackgroundBottom": theme?.colors.tabsInputLabelBackgroundBottom,
    "--dropdownOverlay": theme?.colors.dropdownOverlay,
    "--deleteButtonBG": theme?.colors.deleteButtonBG,
    "--userCardMobileLInksBackground": theme?.colors.userCardMobileLInksBackground,
    "--userCardMobileIconColor": theme?.colors.userCardMobileIconColor,
    "--mobileTabsBackground": theme?.colors.mobileTabsBackground,
    "--dropdownOverlayColor": theme?.colors.dropdownOverlayColor,
    "--contentTopBackground": theme?.colors.contentTopBackground,
    "--contentBottomBackground": theme?.colors.contentBottomBackground,
    "--currentMonthDays": theme?.colors.currentMonthDays,
    "--previoustMonthDays": theme?.colors.previoustMonthDays,
    "--inputValueColor": theme?.colors.inputValueColor,
    "--myPageMobileBG": theme?.colors.myPageMobileBG,
    "--buttonLabel": theme?.colors.buttonLabel,
    "--userCardProgressBarMobileBG": theme?.colors.userCardProgressBarMobileBG,
    "--mobileTopModalBG": theme?.colors.mobileTopModalBG,
    "--modalSectionsBorderColor": theme?.colors.modalSectionsBorderColor,
    "--disabledButtonBG": theme?.colors.disabledButtonBG,
    "--mobileTabsTopHeaderBG": theme?.colors.mobileTabsTopHeaderBG,
    "--loginModalBG": theme?.colors.loginModalBG,
    "--loginInputFieldBG": theme?.colors.loginInputFieldBG,
    "--loginInputColor": theme?.colors.loginInputColor,
    "--loginTitleColor": theme?.colors.loginTitleColor,
    "--loginPlaceholderColor": theme?.colors.loginPlaceholderColor,
    "--loginInputLableBG": theme?.colors.loginInputLableBG,
    "--loginCloseButtonColor": theme?.colors.loginCloseButtonColor,
    "--LoginPageMobileBG": theme?.colors.LoginPageMobileBG,
    "--LoginPageMobileSecondaryBG": theme?.colors.LoginPageMobileSecondaryBG,
    "--registerPageBG": theme?.colors.registerPageBG,
    "--inputFieldErrorBorderColor": theme?.colors.inputFieldErrorBorderColor,
    "--modalCloseBtnColor": theme?.colors.modalCloseBtnColor,
    "--dataPickerInputColor": theme?.colors.dataPickerInputColor,
    "--registerCaptchaTextColor": theme?.colors.registerCaptchaTextColor,
    "--registerMobileBG": theme?.colors.registerMobileBG,
    "--loginPageBG": theme?.colors.loginPageBG,
    "--modalsBoxShadow": theme?.colors.modalsBoxShadow,
    "--refferalCodeInputBG": theme?.colors.refferalCodeInputBG,
    "--refferalCodeInputBGMobile": theme?.colors.refferalCodeInputBGMobile,
    "--captchaInputBG": theme?.colors.captchaInputBG,
    "--globalAlertBoxShadow": theme?.colors.globalAlertBoxShadow,
    "--levelNameOpacity": theme?.colors.levelNameOpacity,
    "--disabledButtonColor": theme?.colors.disabledButtonColor,
    "--slotsActiveTabsBG": theme?.colors.slotsActiveTabsBG,
    "--slotsTabBG": theme?.colors.slotsTabBG,
    "--slotsCountColor": theme?.colors.slotsCountColor,
    "--slotsCountActiveColor": theme?.colors.slotsCountActiveColor,
    "--slotsTextColor": theme?.colors.slotsTextColor,
    "--slotsTextActiveColor": theme?.colors.slotsTextActiveColor,
    "--slotsDividerColor": theme?.colors.slotsDividerColor,
    "--slotsTabBorderColor": theme?.colors.slotsTabBorderColor,
    "--gameCardColor": theme?.colors.gameCardColor,
    "--gameCardBG": theme?.colors.gameCardBG,
    "--slotsGameCardThumbnailBG": theme?.colors.slotsGameCardThumbnailBG,
    "--slotsGamesListBG": theme?.colors.slotsGamesListBG,
    "--slotsTabBorder": theme?.colors.slotsTabBorder,
    "--slotsSearchDropdownBorderColor": theme?.colors.slotsSearchDropdownBorderColor,
    "--paginationBG": theme?.colors.paginationBG,
    "--deleteButtonColor": theme?.colors.deleteButtonColor,
    "--slotsModalShadow": theme?.colors.slotsModalShadow,
    "--liveCasinoModalInnerBGBefore": theme?.colors.liveCasinoModalInnerBGBefore,
    "--liveCasinoModalInnerBGAfter": theme?.colors.liveCasinoModalInnerBGAfter,
    "--captchaInputMobileBG": theme?.colors.captchaInputMobileBG,
    "--mobileRegisterInputLableBG1": theme?.colors.mobileRegisterInputLableBG1,
    "--mobileRegisterInputLableBG2": theme?.colors.mobileRegisterInputLableBG2,
    "--mobileRegisterInputLableBG3": theme?.colors.mobileRegisterInputLableBG3,
    "--affiliateLabelColor": theme?.colors.affiliateLabelColor,
    "--otpBoxshadowColor": theme?.colors.otpBoxshadowColor,
    "--evoPowerballThumbnailBG": theme?.colors.evoPowerballThumbnailBG,
    "--evoPowerballThumbnailMobileAfterBG":
      theme?.colors.evoPowerballThumbnailMobileAfterBG,
    "--evoPowerballThumbnailMobileBeforeBG":
      theme?.colors.evoPowerballThumbnailMobileBeforeBG,
    "--slotsThumbnailSkeletonBG": theme?.colors.slotsThumbnailSkeletonBG,
    "--customerSupportDividerColor": theme?.colors.customerSupportDividerColor,
    "--navigationOverlay": theme?.colors.navigationOverlay,
    "--restrictPageContentBG": theme?.colors.restrictPageContentBG,
    "--disabledOverlayContainerBG": theme?.colors.disabledOverlayContainerBG,
    "--disabledOverlayContentBG": theme?.colors.disabledOverlayContentBG,
    "--disabledOverlayContentBorderColor":
      theme?.colors.disabledOverlayContentBorderColor,
    "--freegameThumbnailBG": theme?.colors.freegameThumbnailBG,
    "--freegameThumbnailBGMobile": theme?.colors.freegameThumbnailBGMobile,
    
  }
}
