import {useEffect} from "react"
import shallow from "zustand/shallow"
import usePusherStore from "stores/usePusherStore"
import {useUserStore} from "stores/userStore"
import PUSHER_EVENTS from "provider/pusher/pusherEvents"
import useWithdrawEvents from "provider/pusher/events/useWithdrawEvents"
import useDepositEvents from "provider/pusher/events/useDepositEvents"
import usePlayerEvents from "provider/pusher/events/usePlayerEvents"
import useAdminEvents from "provider/pusher/events/useAdminEvents"
import useBankEvents from "provider/pusher/events/useBankEvents"

const usePlayerPrivateNotifChannel = () => {
  const id = useUserStore(store => store.userDetails?.player_id, shallow)

  const [pusher, setPrivateNotifChannel] = usePusherStore(
    store => [store.pusher, store.setPrivateNotifChannel],
    shallow,
  )

  useEffect(() => {
    if (!pusher || !id) return

    // console.log("init private notif channel")
    const channelName = `${PUSHER_EVENTS.PRIVATE_CHANNEL}-${id}`
    const privateChannel = pusher.subscribe(channelName)
    setPrivateNotifChannel(privateChannel)

    return () => {
      const pusherStore = usePusherStore.getState()
      const channel = pusherStore.publicGameNotifChannel
      if (channel) {
        // console.log("destroy private notif Channnel")
        channel.unbind_all()
        channel.unsubscribe()
        channel.disconnect()
      }
    }
  }, [pusher, id])

  useWithdrawEvents()
  useDepositEvents()
  usePlayerEvents()
  useAdminEvents()
  useBankEvents()
}

export default usePlayerPrivateNotifChannel
