import React from "react"
import {THEME3} from "screens/Home/MainV3/assets"
import {LogoIcon} from "components/SideBarButton/icons/LogoIcon"
import s from "../layout/Footer/desktopFooter.module.scss"
import login_BG from "assets/login_BG_V3.png"
import register_BG from "assets/register_BG_V3.png"
import pendingVideoDark from "screens/Home/Account/Screens/components/icons/pendingVidBlack.mp4"
import restrictVideo from "screens/Home/Account/Screens/components/icons/restrictVideo.mp4"
import maintenanceVideo from "screens/Home/Account/Screens/components/icons/maintenanceVideoDark.mp4"

export const themeV3 = {
  colors: {
    background: "#000",
    footerBackground: "#001B29",
    bodyBackground: "linear-gradient(180deg, #000 0%, #001B29 100%)",
    text: "#fff",
    bulletBackground: "#ff9d00",
    loginActive: "#ff9d00",
    expandIconBackground: "#fff",
    expandBgColor: "transparent",
    borderColor: "#fff",
    buttonBackground: "#fff",
    buttonTextColor: "#000",
    buttonActiveTextColor: "#1fbfff",
    buttonActivebottomLine:
      "linear-gradient(to right,rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.2) 10%,#1fbfff 50%,rgba(255, 255, 255, 0.2) 90%,rgba(255, 255, 255, 0) 100%)",
    headerHoverColor: "#1fbfff",
    headerActiveColor: "#1fbfff",
    carouselArrowsColor: "#fff",
    footerIconsColor: "#fff",
    footerLanguageSwitcherBG: "#595959",
    footerLanguageSwitcherBGActive: "#CDCDCD",

    sidebarBG: "#161616",
    sidebarIconColor: "#1fbfff",
    sidebarIconBG: "#222222",
    sidebarText_arrowsColor: "#FFFFFF",
    sidebarActiveRowColor: "#1fbfff",
    sidebarActiveText_arrowsColor: "#000",
    bordersColor: "#444447",

    // My page styles
    customerSupportDividerColor: "#e0e0e02b",
    containerTopBackground: "linear-gradient(180deg, #000 0%, #001B29 100%)",
    containerBottomBackground: "transparent",
    paginationBG: "#1c2c3c",
    myPageMobileBG: "linear-gradient(180deg, #000 0%, #001B29 100%)",
    topTitleBackground: "#000",
    pageTitleColor: "#C6C6C6",
    pageTitleHoverColor: "#1fbfff",
    dataPickerInputBackground: "#00A4F9",
    dataPickerInputColor: "#222529",
    dataPickerInputBoxShadow:
      "0px 0.522px 0.522px 0px rgba(255, 255, 255, 0.00) inset, 0px 2.087px 8.348px 0px rgba(0, 0, 0, 0.15)",
    dataPickerInputBackdropFilter: "blur(7.884561061859131px)",
    dataPickerModalBackground:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.85) -49.78%, rgba(37, 61, 83, 0.85) 119.71%)",
    dataPickerModalBoxshadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.70)",
    dataPickerModalBackdropFilter: "blur(3.5px)",
    dataPickerModalFilterbuttonBackground:
      "linear-gradient(180deg, #000 -49.78%, #253D53 101.67%), #00A4F9",
    dataPickerModalFilterbuttonColor: "#AEAEAE",
    dataPickerModalFilterbuttonActiveBackground: "#00A4F9",
    dataPickerModalConfirmButtonBackground:
      "linear-gradient(0deg, #00A4F9 0%, #00A4F9 100%), #D9D9D9",
    dataPickerModalCancelButtonBackground: "#004E77",
    dataPickerModalRdrStartEndEdgeBackground:
      "linear-gradient(182deg, #000 -28.47%, #121D27 128.18%), #D9D9D9",
    dataPickerModalRdrWeekDayColor: "#787878",
    //
    hsitoryTabBackground: "linear-gradient(180deg, #141C24 71.16%, #253D53 101.64%)",
    tabBorderBottom: "#787878",
    hsitoryTabTotalValueColor: "#fff",
    hsitoryTabTotalHeadColor: "#C6C6C6",
    //
    userCardBackground:
      "linear-gradient(180deg, #253D53 -49.78%, #1B252F 32.76%), linear-gradient(180deg, #253D53 -49.78%, #000 101.67%), linear-gradient(180deg, #253D53 -49.78%, #000 81.23%, #3D2501 101.67%)",
    userCardTitlesColor: "#fff",
    userCardRibbonLevelNameColor: "#fff",
    userCardRibbonLevelNameOpacity: "0.2",
    userCardProgressBarDesktopColor: "#787878",
    userCardProgressBarMobileBG: "transparent",

    //
    sidebarBackground: "linear-gradient(180deg, #141C24 71.16%, #253D53 101.64%)",
    sidebarItemColor: "#C6C6C6",
    sidebarItemActiveColor: "#FFF",
    sidebarItemActiveIconColor: "#00acee",
    //
    tabsContainerBackground:
      "linear-gradient(180deg, #141C24 71.16%, #253D53 101.64%)",
    tabsColor: "#a7a3a3",
    tabsActiveColor: "#FFF",
    tabsUnderlineColor: "#787878",
    tabsUnderlineActiveColor: "#00acee",
    tabsInputLabelBackground: "#141c24",
    tabsInputLabelBackgroundBottom: "#0E1821",
    tabsInputPlaceholder: "#5E5E5E",
    preAmountButtonBackground: "#121D27",
    preAmountButtonColor: "#FFF",
    preAmountButtonActiveBackground: "#00A4F9",
    infoLabelColor: "#AEAEAE",
    readbuttonBackground: "linear-gradient(0deg, #00A4F9 0%, #00A4F9 100%), #D9D9D9",
    mobileInputLabelBackground: "#141c24",
    tabletTopTabsContainerBackground: "#151C24",
    //
    myInfoColor: "#fff",
    inputFieldErrorColor: "#f59300",
    inputFieldErrorBorderColor: "#f59300",
    dropdownOverlay: "rgba(0, 0, 0, 0.4)",
    deleteButtonBG: "#F59300",
    deleteButtonColor: "#222529",
    //
    userCardMobileLInksBackground:
      "linear-gradient(180deg, #000 -49.78%, #253D53 101.67%)",
    userCardMobileIconColor: "#00A4F9",
    mobileTabsBackground: "linear-gradient(180deg, #141C24 71.15%, #253D53 101.62%)",
    dropdownOverlayColor: "#13202D",
    contentTopBackground: "#1C2B3B",
    contentBottomBackground: "#0E151D",
    currentMonthDays: "#d3d3d3",
    previoustMonthDays: "#525252",
    inputValueColor: "#ffffff",
    buttonLabel: "#222529",
    //
    mobileTopModalBG: "transparent",
    modalSectionsBorderColor: "#353535",
    disabledButtonBG: "#223040",
    disabledButtonColor: "#5F5F5F",
    mobileTabsTopHeaderBG: "#000",
    //
    loginModalBG: "linear-gradient(180deg, #141C24 71.15%, #253D53 101.62%)",
    loginInputFieldBG: "transparent",
    loginInputColor: "#AEAEAE",
    loginInputLableBG: "#151C25",
    loginTitleColor: "#fff",
    loginPlaceholderColor: "#787878",
    loginCloseButtonColor: "#C6C6C6",
    LoginPageMobileBG: "linear-gradient(180deg, #000 0%, #001B29 100%)",
    LoginPageMobileSecondaryBG: "transparent",
    registerPageBG: "linear-gradient(180deg, #141C24 71.15%, #253D53 101.62%)",
    modalCloseBtnColor: "#C6C6C6",
    registerCaptchaTextColor: "#fff",
    registerMobileBG: "linear-gradient(180deg, #000 0%, #001B29 100%)",
    loginPageBG: "linear-gradient(180deg, #000 0%, #001B29 100%)",
    modalsBoxShadow: "0px 0px 15px 2px rgba(255, 251, 251, 0.4)",
    refferalCodeInputBG: "#131C24",
    refferalCodeInputBGMobile: "#02121c",
    captchaInputBG: "#192430",
    captchaInputMobileBG: "#021623",
    globalAlertBoxShadow: "0px 0px 50px 2px #008fc78f",
    levelNameOpacity: "0.07",
    mobileRegisterInputLableBG1: "#000508",
    mobileRegisterInputLableBG2: "#010d14",
    mobileRegisterInputLableBG3:
      "linear-gradient(180deg, rgba(2,18,28,1) 0%, rgba(21,28,37,1) 100%)",
    // SLOTS AND LIVE CASINO
    slotsTabBG: "linear-gradient(180deg, #000 -49.78%, #253D53 101.67%)",
    slotsActiveTabsBG: "linear-gradient(0deg, #00A4F9 0%, #00A4F9 100%), #D9D9D9",
    slotsCountColor: "#00A4F9",
    slotsCountActiveColor: "#fff",
    slotsTextColor: "#C6C6C6",
    slotsTextActiveColor: "#fff",
    slotsDividerColor: "#253D53",
    slotsTabBorderColor: "transparent",
    gameCardColor: "#AEAEAE",
    gameCardBG: "transparent",
    slotsGameCardThumbnailBG: "transparent",
    slotsGamesListBG: "transparent",
    slotsTabBorder: "none",
    slotsSearchDropdownBorderColor: "#253D53",
    slotsModalShadow: "0px 0px 50px 2px #ffffff8f",
    liveCasinoModalInnerBGBefore: "none",
    liveCasinoModalInnerBGAfter: "none",
    slotsThumbnailSkeletonBG: "linear-gradient(90deg, #000000, #001B29, #000000)",
    // AFFILIATE PAGE
    affiliateLabelColor: "#00acee",
    otpBoxshadowColor:
      "0 1px #d1d1d145 inset, 0 -1px #d1d1d145 inset, 0px 0 #d1d1d145 inset, 0px 0 #d1d1d145 inset",
    // PowerBall Page
    evoPowerballThumbnailBG:
      "linear-gradient(0deg, #001b29, #001622 80%, #00162200)",
    evoPowerballThumbnailMobileAfterBG:
      "linear-gradient(0deg, #011c29, #011c29 20%, rgba(252, 254, 255, 0))",
    evoPowerballThumbnailMobileBeforeBG:
      "linear-gradient(0deg, #001b29, #001623 50%, #011c28)",
    navigationOverlay: "#000",
    restrictPageContentBG: "transparent",
    //
    disabledOverlayContainerBG: "transparent",
    disabledOverlayContentBG: "transparent",
    disabledOverlayContentBorderColor: "transparent",
    freegameThumbnailBG: "linear-gradient(0deg, #001b29de, #001622 80%, #000)",
    freegameThumbnailBGMobile: "linear-gradient(180deg, #000 0%, #001B29 100%)",
    freegameContainerBGMobile:
      "linear-gradient(180deg, #141C24 71.15%, #253D53 101.62%)",
    freegameContainerBoxShadowMobile: "none",
    freegameContainerBorderMobile: "none",
    freegameContainerDescriptionMobileColor: "#00bffb",
    freegameButtonContainerBGMobile:
      "linear-gradient(180deg, #06060e 0%, rgba(6, 6, 14, 0.5) 50%, #000 80%)",
  },

  images: {
    carousel: [
      {
        src: THEME3.SPORTS,
        caption: "SPORTS PREMATCH",
        path: "pre-match-sports",
      },
      {
        src: THEME3.SLOTS,
        caption: "Slots",
        path: "slots",
      },
      {
        src: THEME3.SKYPARK,
        caption: "SKY PARK",
        path: "skypark",
      },
      {
        src: THEME3.CASINO,
        caption: "Casino",
        path: "casino",
      },
      {
        src: THEME3.EVENTS,
        caption: "Events",
        path: "gift",
      },
    ],
    banners: {
      top_banner_desktop: {
        src: THEME3.TOP_BANNER,
        caption: "LIVE SPORTS",
        path: "live-sports",
      },
      bottom_banner_desktop: {
        src: THEME3.BOT_BANNER,
        caption: "Live Casino",
        path: "casino",
      },
      top_banner_mobile: {
        src: THEME3.TOP_BANNER_M,
        caption: "LIVE SPORTS",
        path: "live-sports",
      },
      bottom_banner_mobile: {
        src: THEME3.BOT_BANNER_M,
        caption: "Live Casino",
        path: "casino",
      },
    },
    footerLogo: <LogoIcon className={s.logoNameIcon} />,
    login_BG: login_BG,
    register_BG: register_BG,
    pendingVideo: pendingVideoDark,
    restrictVideoSrc: restrictVideo,
    maintenanceVideoSrc: maintenanceVideo,

    // ...other images
  },
}
