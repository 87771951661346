import React from "react"
import {THEME2} from "screens/Home/MainV3/assets"
import s from "../layout/Footer/desktopFooter.module.scss"
import {LogoIconColored} from "components/SideBarButton/icons/LogoIconColored"
import login_BG from "assets/login_BG_V2.png"
import register_BG from "assets/register_BG_V2.png"
import pendingVideoLight from "screens/Home/Account/Screens/components/icons/pendingVidLight.mp4"
import restrictVideo from "screens/Home/Account/Screens/components/icons/restrictVideoLight.mp4"
import maintenanceVideo from "screens/Home/Account/Screens/components/icons/maintenanceVideoLight.mp4"

export const themeV2 = {
  colors: {
    background: "#00B2FF",
    footerBackground: "#fff",
    bodyBackground: "#fff",
    text: "#000",
    bulletBackground: "#00B2FF",
    loginActive: "#00B2FF",
    expandIconBackground: "#000",
    expandBgColor: "#fff",
    borderColor: "#fff",
    buttonBackground: "#fff",
    buttonTextColor: "#000",
    buttonActiveTextColor: "#00B2FF",
    buttonActivebottomLine:
      "linear-gradient(to right,rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.2) 10%,#8DE4FF 50%,rgba(255, 255, 255, 0.2) 90%,rgba(255, 255, 255, 0) 100%)",
    headerHoverColor: "#0184bf",
    headerActiveColor: "#fff",
    carouselArrowsColor: "#00B2FF",
    footerIconsColor: "rgb(25, 180, 240)",
    footerLanguageSwitcherBG: "#f0f0f0",
    footerLanguageSwitcherBGActive: "#00acee",

    sidebarBG: "#fff",
    sidebarIconColor: "rgb(0, 172, 238)",
    sidebarIconBG: "#fff",
    sidebarText_arrowsColor: "#777777",
    sidebarActiveRowColor: "#00acee",
    sidebarActiveText_arrowsColor: "#fff",
    bordersColor: "#e6e6e6",

    // My page styles
    customerSupportDividerColor: "#e0e0e0",
    containerTopBackground: "rgb(0, 172, 238)",
    containerBottomBackground: "#fff",
    paginationBG: "#fff",
    myPageMobileBG: "#fff",
    topTitleBackground: "rgb(0, 172, 238)",
    pageTitleColor: "#fff",
    pageTitleHoverColor: "#0184bf",
    dataPickerInputBackground: "#19b4f0",
    dataPickerInputColor: "#fff",
    dataPickerInputBoxShadow: "#00000029 0px 3px 6px",
    dataPickerInputBackdropFilter: "unset",
    dataPickerModalBackground: "#fcfcfc",
    dataPickerModalBoxshadow: "unset",
    dataPickerModalBackdropFilter: "unset",
    dataPickerModalFilterbuttonBackground: "#00acee",
    dataPickerModalFilterbuttonColor: "#ffffff",
    dataPickerModalFilterbuttonActiveBackground: " #1fbfff",
    dataPickerModalConfirmButtonBackground: "#19b4f0",
    dataPickerModalCancelButtonBackground: "#f4f4f4",
    dataPickerModalRdrStartEndEdgeBackground: "#1fbfff",
    dataPickerModalRdrWeekDayColor: "#787878",
    //
    hsitoryTabBackground: "#fff",
    tabBorderBottom: "#6ad6ff",
    hsitoryTabTotalValueColor: "#fff",
    hsitoryTabTotalHeadColor: "#747474",
    //
    userCardBackground: "#ffffff",
    userCardTitlesColor: "#333333",
    userCardRibbonLevelNameColor: "#bfbfbf",
    userCardRibbonLevelNameOpacity: "0.2",
    userCardProgressBarDesktopColor: "#daecf8",
    userCardProgressBarMobileBG: "#fff",

    //
    sidebarBackground: "#ffffff",
    sidebarItemColor: "#747474",
    sidebarItemActiveColor: "#00acee",
    sidebarItemActiveIconColor: "#00acee",
    //
    tabsContainerBackground: "#fff",
    tabsColor: "#525252",
    tabsActiveColor: "#1fbfff",
    tabsUnderlineColor: "#00abee3b",
    tabsUnderlineActiveColor: "#00acee",
    tabsInputLabelBackground: "#ffffff",
    tabsInputLabelBackgroundBottom: "#0E1821",
    tabsInputPlaceholder: "#959595",
    preAmountButtonBackground: "#fff",
    preAmountButtonColor: "#525252",
    preAmountButtonActiveBackground: " #f0f0f0",
    infoLabelColor: "#959595",
    readbuttonBackground: "#1da1f2",
    mobileInputLabelBackground: "#fcfcfc",
    tabletTopTabsContainerBackground: "#ffffff",
    //
    myInfoColor: "#525252",
    inputFieldErrorColor: "rgb(250, 57, 57)",
    inputFieldErrorBorderColor: "rgb(250, 57, 57)",
    dropdownOverlay: "rgba(0, 0, 0, 0.4)",
    deleteButtonBG: "#fa3939",
    deleteButtonColor: "#fff",
    //
    userCardMobileLInksBackground: "#fff",
    userCardMobileIconColor: "#00A4F9",
    mobileTabsBackground: "#ffffff",
    dropdownOverlayColor: "#fff",
    contentTopBackground: "#fff",
    contentBottomBackground: "#fff",
    currentMonthDays: "#525252",
    previoustMonthDays: "#d3d3d3",
    inputValueColor: "#525252",
    buttonLabel: "#fff",
    //
    mobileTopModalBG: "#00acee",
    modalSectionsBorderColor: "#e3e3e3",
    disabledButtonBG: "#f4f4f4",
    disabledButtonColor: "#444444",
    mobileTabsTopHeaderBG: "rgb(0, 172, 238)",
    //
    loginModalBG: "#ffffff",
    loginInputFieldBG: "transparent",
    loginInputColor: "#787878",
    loginInputLableBG: "#fff",
    loginTitleColor: "#404040",
    loginPlaceholderColor: "#787878",
    loginCloseButtonColor: "#C6C6C6",
    LoginPageMobileBG: "rgb(0, 172, 238)",
    LoginPageMobileSecondaryBG: "#fff",
    registerPageBG: "#fff",
    modalCloseBtnColor: "#525252",
    registerCaptchaTextColor: "#000",
    registerMobileBG: "#fff",
    loginPageBG: "#00acee",
    modalsBoxShadow: "0px 0px 15px 2px rgba(255, 251, 251, 0.4)",
    refferalCodeInputBG: "#fff",
    refferalCodeInputBGMobile: "#fff",
    captchaInputBG: "#fff",
    captchaInputMobileBG: "#fff",
    globalAlertBoxShadow: "none",
    levelNameOpacity: "0.15",
    mobileRegisterInputLableBG1: "#fff",
    mobileRegisterInputLableBG2: "#fff",
    mobileRegisterInputLableBG3: "#fff",
    // SLOTS AND LIVE CASINO
    slotsTabBG: "#fff",
    slotsActiveTabsBG: "#00acee",
    slotsCountColor: "#555555",
    slotsCountActiveColor: "#fff",
    slotsTextColor: "#555555",
    slotsTextActiveColor: "#fff",
    slotsDividerColor: "#fff",
    slotsTabBorderColor: "#e6e6e6",
    gameCardColor: "#00acee",
    gameCardBG: "#fff",
    slotsGameCardThumbnailBG: "#ebebeb",
    slotsGamesListBG: "hsla(0,0%,98.8%,.95)",
    slotsTabBorder: "1px solid",
    slotsSearchDropdownBorderColor: "#e6e6e6",
    slotsModalShadow: "none",
    liveCasinoModalInnerBGBefore: "linear-gradient(to right, #000000a3, #00000000)",
    liveCasinoModalInnerBGAfter: "linear-gradient(to right, #00000000, #000000a3)",
    slotsThumbnailSkeletonBG: "linear-gradient(90deg, #ebebeb, #f5f5f5, #ebebeb)",
    // AFFILIATE PAGE
    affiliateLabelColor: "#525252",
    otpBoxshadowColor:
      "0 1px #d1d1d1 inset, 0 -1px #d1d1d1 inset, 0px 0 #d1d1d1 inset, 0px 0 #d1d1d1 inset",
    // PowerBall Page
    evoPowerballThumbnailBG:
      "linear-gradient(0deg, #fcfeff, #fcfeff 20%, #fcfeff00)",
    evoPowerballThumbnailMobileAfterBG:
      "linear-gradient(0deg,#233f4a,#233f4a 20%,rgba(252,254,255,0))",
    evoPowerballThumbnailMobileBeforeBG:
      "linear-gradient(180deg, #233f4a, #233f4a 50%, #d8e9ed)",
    navigationOverlay: "#fff",
    restrictPageContentBG: "#fff",
    //
    disabledOverlayContainerBG: "#ffffff14",
    disabledOverlayContentBG: "#fff",
    disabledOverlayContentBorderColor: "#00acee",
    freegameThumbnailBG:
      "linear-gradient(180deg,rgba(6, 6, 14, 1) 0%,rgba(6, 6, 14, 0.5) 50%,rgba(255, 255, 255, 0) 80%)",
    freegameThumbnailBGMobile:
      "linear-gradient(rgba(255, 255, 255, 0.001), rgba(255, 255, 255, 0.001)),url(/assets/img/footer-background.png) no-repeat",
    freegameContainerBGMobile: "rgba(255, 255, 255, 0.5)",
    freegameContainerBoxShadowMobile: "0px 2px 8px 2px #ccccccad",
    freegameContainerBorderMobile: "0.5px solid #ccc",
    freegameContainerDescriptionMobileColor: "#003a4c",
    freegameButtonContainerBGMobile:
      "linear-gradient(180deg,#06060e 0%,rgba(6, 6, 14, 0.5) 50%,rgba(255, 255, 255, 0) 80%)",
  },

  images: {
    carousel: [
      {
        src: THEME2.SPORTS,
        caption: "SPORTS PREMATCH",
        path: "pre-match-sports",
      },
      {
        src: THEME2.SLOTS,
        caption: "Slots",
        path: "slots",
      },
      {
        src: THEME2.SKYPARK,
        caption: "SKY PARK",
        path: "skypark",
      },
      {
        src: THEME2.CASINO,
        caption: "Casion",
        path: "casino",
      },
      {
        src: THEME2.EVENTS,
        caption: "Events",
        path: "gift",
      },
    ],
    banners: {
      top_banner_desktop: {
        src: THEME2.TOP_BANNER,
        caption: "LIVE SPORTS",
        path: "live-sports",
      },
      bottom_banner_desktop: {
        src: THEME2.BOT_BANNER,
        caption: "Live Casino",
        path: "casino",
      },
      top_banner_mobile: {
        src: THEME2.TOP_BANNER_M,
        caption: "LIVE SPORTS",
        path: "live-sports",
      },
      bottom_banner_mobile: {
        src: THEME2.BOT_BANNER_M,
        caption: "Live Casino",
        path: "casino",
      },
    },
    footerLogo: <LogoIconColored className={s.logoNameIcon} />,
    login_BG: login_BG,
    register_BG: register_BG,
    pendingVideo: pendingVideoLight,
    restrictVideoSrc: restrictVideo,
    maintenanceVideoSrc: maintenanceVideo,

    // ...other images
  },
}
