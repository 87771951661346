// const Suspicious = "/assets/banner/home/Suspicious02.webp"

import {BTI_SPORTS, PROMOTIONS} from "screens/NavHome/utils/config"
import {
  NewSportsBanner,
  ScammerBanner,
  UefaBanner,
  ChickenCokeBanner,
  DepositBanner,
  EvoBetWinBanner,
  SlotsBanner,
  MilageBanner,
  ComboBonusBanner,
  FreeGameBanner,
  NewMemberBanner,
  SportsExistingMemberBanner,
  CasinoFirstDepoBanner,
} from "screens/Home/MainV2/assets"

export const POPUP_BANNER_TYPES = {
  NEWSPORTS: "new_sports",
  FREE_GAMES: "slots",
  INFINITE_DEPOSIT: "infinite-deposit",
  EVO_BET_WIN: "evo-bet-win",
  CHICKEN_COKE: "chicken-coke",
  UEFA: "uefa",
  MILEAGE: "mileage",
  FREE_GAME: "free-game",
}

const NEW_SPORTS = {
  name: "new_sports",
  assets: {
    l: NewSportsBanner,
    s: NewSportsBanner,
  },
  onClickRedirectTo: BTI_SPORTS,
  background: "radial-gradient(circle at 0% 100%, #015593, #56CBF8, #0B639A)",
  title: "스포츠 컨텐츠 업데이트 완료 안내",
  description:
    "이지벳이 스포츠 컨텐츠 업데이트와 함께 더욱 다양한스포츠\n서비스 제공을 시작하게 되었습니다.",
}

const SCAMMER = {
  name: "suspicious",
  assets: {
    l: ScammerBanner,
    s: ScammerBanner,
  },
  onClickRedirectTo: PROMOTIONS,
  background: "linear-gradient(0deg, #CC6693, #615B64)",
  title: "이지벳 사칭 주의!",
  description:
    "이지벳은 회원의 개별 연락처를 통해 연락을 하지 않습니다.\n주의 부탁드립니다",
}

const UEFA = {
  name: "uefa",
  assets: {
    l: UefaBanner,
    s: UefaBanner,
  },
  onClickRedirectTo: PROMOTIONS,
  background: "linear-gradient(0deg, #ACFFBF, #ACF8C0)",
  title: "유럽 챔피언스 리그 결승 돌발이벤트",
  description: "자세한 내용은 이벤트 페이지에서 확인해주세요",
  isNew: true,
}

const CHICKEN = {
  name: "chicken-coke",
  assets: {
    l: ChickenCokeBanner,
    s: ChickenCokeBanner,
  },
  onClickRedirectTo: PROMOTIONS,
  background: "radial-gradient(circle at 0% 100%, #FF9D00, #FF0000, #F49703)",
  title: "신규 가입 기프티콘 지급 이벤트",
  description:
    "신규 가입 회원을 대상으로 기프티콘을 지급해 드리고 있습니다. 자세한 내용은 이벤트 페이지에서 확인하시고 혜택 받아보세요~",
}

const DEPOSIT = {
  name: "infinite-deposit",
  assets: {
    l: DepositBanner,
    s: DepositBanner,
  },
  onClickRedirectTo: PROMOTIONS,
  background: "radial-gradient(circle at 0% 100%, #FF0000, #FCCC02, #C30202)",
  title: "카지노 및 가상 스포츠 컨텐츠 무한 충전 이벤트 출시!!",
  description:
    "카지노와 가상 스포츠 컨텐츠 이용 회원님들을\n위한 새로운 이벤트가 시작되었습니다.",
}

const EVO_BET_WIN = {
  name: "evo-bet-win",
  assets: {
    l: EvoBetWinBanner,
    s: EvoBetWinBanner,
  },
  onClickRedirectTo: PROMOTIONS,
  background: "radial-gradient(circle at 0% 100%, #063793, #A94EF7, #0B2C9A)",
  title: "에불루션 BET & WIN 캐시 드롭 이벤트 출시 !!!",
  description:
    "새로운 에불루션 이벤트와 함께 행운의 주인공이 되어보세요.\n자세한 내용은 이벤트 공지사항 참고해주시기 바랍니다.",
}

const SLOTS = {
  name: "slots",
  assets: {
    l: SlotsBanner,
    s: SlotsBanner,
  },
  onClickRedirectTo: PROMOTIONS,
  background: "radial-gradient(circle at 0% 100%, #660193, #56C0F8, #6E0B9A)",
  title: "팡! 팡! 터지는 잭팟 & 프리스핀",
  description: "슬롯 팡팡 이벤트 매일 첫 충 30% 매충 15%",
}

const MILAGE = {
  name: "milage",
  assets: {
    l: MilageBanner,
    s: MilageBanner,
  },
  onClickRedirectTo: PROMOTIONS,
  background: "radial-gradient(circle at 0% 100%, #1C5178, #14B2EF, #0B639A)",
  title: "마일리지 적립 이벤트가 시작되었습니다",
  description: "자세한 내용은 이벤트 공지사항에서 확인해주세요",
}

const COMBO_BONUS = {
  name: "milage",
  assets: {
    l: ComboBonusBanner,
    s: ComboBonusBanner,
  },
  onClickRedirectTo: PROMOTIONS,
  background: "radial-gradient(circle at 0% 100%, #1C4878, #469CBD, #0D284E)",
  title: "멀티 베팅 보너스 진행중 !!",
  description: "멀티로 베팅할 수록 더 많은 혜택을 드립니다.",
}

const FREE_GAME = {
  name: "free-game",
  assets: {
    l: FreeGameBanner,
    s: FreeGameBanner,
  },
  onClickRedirectTo: PROMOTIONS,
  background: "radial-gradient(circle at 0% 100%, #FF9D00, #FCBE34, #934519)",
  title: "무료 체험 게임 진행중",
  description: "무료로 게임도 즐기고 상금의 행운에도 도전해보세요~",
}

const NEW_MEMBERS = {
  name: "free-game",
  assets: {
    l: NewMemberBanner,
    s: NewMemberBanner,
  },
  onClickRedirectTo: PROMOTIONS,
  background: "radial-gradient(circle at 0% 100%, #1C5178, #14B2EF, #0B639A)",
  title: "스포츠 신규 회원 최대 100% 보상 이벤트",
  description: "스포츠 신규 회원에게 드리는 엄청난 혜택을 확인해보세요~",
}

const SPORTS_EXISTING_MEMBERS = {
  name: "sports-existing-members",
  assets: {
    l: SportsExistingMemberBanner,
    s: SportsExistingMemberBanner,
  },
  onClickRedirectTo: PROMOTIONS,
  background: "radial-gradient(circle at 0% 100%, #55BEFF, #050E1D, #0B3A53)",
  title: "스포츠 기존회원 충전 이벤트 출시!!!",
  description: "기존 이용 회원들에게 드리는 특별한 혜택",
  isNew: true,
}

const CASINO_FIRST_DEPOSIT = {
  name: "casino-first-deposit",
  assets: {
    l: CasinoFirstDepoBanner,
    s: CasinoFirstDepoBanner,
  },
  onClickRedirectTo: PROMOTIONS,
  background: "radial-gradient(circle at 0% 100%, #022A5C, #0581BD, #035D94)",
  title: "카지노 첫충 / 무한 매충 이벤트 _ NEW !!!",
  description: "이제는 카지노 보너스를 롤링 300% 로 즐겨보세요~",
  isNew: true,
}

export const HOME_BANNERS = [
  // NEW_SPORTS,
  SCAMMER,
  SPORTS_EXISTING_MEMBERS,
  NEW_MEMBERS,
  FREE_GAME,
  COMBO_BONUS,
  MILAGE,
  CHICKEN,
  DEPOSIT,
  EVO_BET_WIN,
  SLOTS,
]

export const EZ_HOME_BANNERS = [
  SCAMMER,
  FREE_GAME,
  MILAGE,
  CHICKEN,
  DEPOSIT,
  EVO_BET_WIN,
  SLOTS,
]
