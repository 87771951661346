import React from "react"
import IconProps from "./IconProps"

const ArrowDown: React.FC<IconProps> = ({fill = "#fff", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="34"
    viewBox="0 0 37 34"
    {...props}
  >
    <g id="Group_39539" data-name="Group 39539" transform="translate(-179 -662)">
      <g
        id="Rectangle_18477"
        data-name="Rectangle 18477"
        transform="translate(216 662) rotate(90)"
        fill="none"
        stroke={fill}
        stroke-width="1"
      >
        <rect width="34" height="37" rx="8" stroke="none" />
        <rect x="0.5" y="0.5" width="33" height="36" rx="7.5" fill="none" />
      </g>
      <path
        id="Path_86698"
        data-name="Path 86698"
        d="M0,0C.4.287,7,7.5,7,7.5L0,15"
        transform="translate(205 671.4) rotate(90)"
        fill="none"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_86700"
        data-name="Path 86700"
        d="M0,0C.4.287,7.028,7.49,7.028,7.49L0,15"
        transform="translate(205 680.4) rotate(90)"
        fill="none"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
)
export default ArrowDown
